<template>
  <th-page-wrapper>
    <!-- Table -->
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      do-route-filters
      :show-operations="false"
      no-meta-check
      :route-base="routeBase"
      resource="paymentOptions"
      :resource-query="{ query: { deleted: false } }"
      :buttons="computedButtons"
      show-search-filter
      :search-filters="filtersList"
      :search-filter-config="{ allowTextInput: true }"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      :transform-fetched-data="getIndexesList"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import { usePaymentOptionsStore } from '@/store/paymentOptions'
import typeOf from 'just-typeof'
import flush from 'just-flush'
import { getTypesLabels } from './helpers'

export default {
  setup() {
    const paymentOptionsStore = usePaymentOptionsStore()

    return { paymentOptionsStore }
  },
  data() {
    return {
      routeBase: '/accounting/payment_options',
      typesLabels: getTypesLabels(this),
      filtersList: [
        {
          name: 'type',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('common.headers.type.title'),
          options: [],
          filterable: true
        },
        {
          name: 'account_number',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.payment_options.all.headers.fa_account_number'),
          options: [],
          filterable: true
        },
        {
          name: 'active',
          type: 'select',
          label: this.$t('pages.payment_options.all.headers.status'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          options: [
            {
              value: null,
              label: this.$t('common.forms.labels.all')
            },
            {
              value: 'true',
              label: this.$t('common.forms.labels.active')
            },
            {
              value: 'false',
              label: this.$t('common.forms.labels.inactive')
            }
          ]
        }
      ],
      headers: [
        {
          field: 'order_index',
          label: this.$t('pages.payment_options.all.headers.order_index'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => (row?.type ? this.typesLabels[row.type] : '-')
        },
        {
          field: 'currency',
          label: this.$t('pages.payment_options.all.headers.currency'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'fa_account_number',
          label: this.$t('pages.payment_options.all.headers.fa_account_number'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'active',
          label: this.$t('pages.payment_options.all.headers.status'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            if (!row || typeOf(row.active) !== 'boolean') return '-'

            return row.active
              ? this.$t(
                  'pages.payment_options.edit.form.properties.active.active.label'
                )
              : this.$t(
                  'pages.payment_options.edit.form.properties.active.inactive.label'
                )
          }
        }
      ],
      computedButtons: [
        {
          type: 'create',
          scopes: ['accounting:payment_options:create']
        }
      ],
      selectedItems: []
    }
  },
  async mounted() {
    const resources = [
      { name: 'type', key: 'type' },
      { name: 'account_number', key: 'fa_account_number' }
    ]
    const getLabel = (filter, name) =>
      (filter === 'type' && this.typesLabels[name]) || name

    let res
    try {
      const { paymentOptions } = await this.$resourceFetch('paymentOptions')
      res = paymentOptions
    } catch (err) {
      this.$logException(err, { trackError: false })
    }

    this.filtersList = this.filtersList.map((filter) => {
      const resource = resources.find((r) => r.name === filter.name)
      if (resource) {
        const names = res.map((res) => res[resource.key])
        const uniqueNames = new Set(names)
        const resourceOptions = flush([...uniqueNames]).map((name) => ({
          value: name,
          label: getLabel(resource.name, name)
        }))

        return {
          ...filter,
          options: resourceOptions
        }
      }

      return filter
    })
  },
  methods: {
    getIndexesList(data) {
      // Create the list of current indexes
      const newIndexesList = data.map((res) => {
        return res.order_index
      })
      // Check againts the older indexes, if there are less then we don't store them, because of filtering results
      // The list is going to be emptied when deleting a payment option
      if (
        this.paymentOptionsStore.indexesList.length <= newIndexesList.length
      ) {
        this.paymentOptionsStore.setIndexesList(newIndexesList)
      }
      return data
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.payment_options.title')
        })
      })
    }
  }
}
</script>

<style scoped></style>
