import { defineStore } from 'pinia'

export const usePaymentOptionsStore = defineStore('paymentOptions', {
  state: () => ({ indexesList: [] }),
  actions: {
    setIndexesList(indexesList) {
      // Keep the array in ascending order
      this.indexesList = [...indexesList].sort((a, b) => a - b)
    }
  }
})
